.title-component {
    width: 100%;
    text-align: center;
}

.title-text {
    font-family: "Monomaniac One", sans-serif;
    font-size: 50px;
    margin: 10px 0 0 0;
    cursor: pointer;
}