.result-title {
  font-family: "Monomaniac One", sans-serif;
  margin-bottom: 20px;
  text-align: center;
  color: #B49700;
  font-size: 30px;
}

.result-value {
  margin: 10px 0px;
  color: #B49700;
  font-size: 20px;
}

.result-container {
  font-family: "Monomaniac One", sans-serif;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
