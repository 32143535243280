.call-to-action-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: right;
  margin-bottom: 40px;
}

.call-to-action-text {
  font-family: "Monomaniac One", sans-serif;
  color: #b49700;
  font-size: 35px;
  text-align: right;
  margin: 0px;
  position: relative;
  top: 40px;
  flex-shrink: 0;
}

.certification-img {
  position: relative;
  width: 350px;
  left: 140px;
}

@media (max-width: 600px) {
  .call-to-action-text {
    font-size: 30px;
  }
  .certification-img {
    width: 250px;
    left: 110px;
  }
}

@media (max-width: 400px) {
  .call-to-action-text {
    font-size: 28px;
  }
  .certification-img {
    width: 200px;
    left: 80px;
    top: 30px;
  }
}
