.contract-input-content-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contract-input-component-spacer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.text-input-container {
    display: flex;
    flex-grow: 1;
}

.label {
  font-family: "Monomaniac One", sans-serif;
  color: #b49700;
  font-size: 35px;
  margin: 0 0 10px 10px;
}

.contract-text {
  background-color: #fff5d0;
  border-radius: 20px;
  padding: 20px;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  overflow: auto;
  margin-bottom: 10px;
  border: solid 3px #fff5d0;
  font-family: "Inter", sans-serif;
  color: #6f6f6f;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

textarea::-webkit-scrollbar {
  width: 7px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #f5c721;
  border-radius: 50px;
}

.file-upload-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 400px) {
  .contract-input-content-container {
    height: 85vh;
  }
}